
import React, { Component } from 'react';
import styles from "./ExistingPaymentType.module.scss"
import { formatNumber } from '../../helpers/utils';
import {configuration} from '../../services/Checkout.settings.service';
import {
    useStripe,
    useElements,
    Elements
} from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import ChargeError from './ChargeError';
import {CartService} from '../../services/cart.service';
import { JwtContext } from '../../contexts/JwtContext';
import parse from 'html-react-parser';



const ExistingPaymentTypeFormInner = ({cardid, cardnumber, expires, price,currency, terms, customer_terms, disable, cart, voucher, onComplete, onIdle, onBusy}) => {
    const [error, setError] = React.useState('');
    const stripe =  useStripe();
    const elements = useElements();
    const [invoice, setInvoice] = React.useState( null );
    const {jwt} = React.useContext(JwtContext);
    const [lock, setLock] = React.useState(false);
    const [timer, setTimer] = React.useState(2000);
    const [retry, setRetry] = React.useState(0);

    const onErrorClear = () => {
        setError('');
    }

    const finalizeIntent = (response, result, currentRetry = retry, currentTimeout = timer) => {
        console.log("timeout + retry", currentTimeout, currentRetry);
        
        setTimeout(() => {
            let chain = '';
            chain = chain + '&object=' + encodeURIComponent(JSON.stringify(result.paymentIntent));
            chain = chain + '&invoice=' + encodeURIComponent(response.record); 
            chain = chain + '&cart=' + encodeURIComponent(JSON.stringify(response.cart)); 
            
            onBusy();
    
            fetch(configuration.host + "iapi/ajax-finalize-invoice", {
                body: configuration.tokenname + '=' + configuration.tokenvalue + chain,
                method: "POST",
                credentials: 'include',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": 'XMLHttpRequest',
                    "X_REQUESTED_WITH": 'xmlhttprequest',
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(res => {
                return res.json();
            })
            .then(r => {
                const nextRetry = currentRetry + 1;
                setRetry(nextRetry);
    
                if(r.status === 'OK') {
                    onIdle();
                    onComplete(true);
                    setLock(false);
                }
                else if(parseInt(r?.status) === 429) {
                    if(nextRetry < 8) {
                        const nextTimeout = currentTimeout * 2;
                        setTimer(nextTimeout);
                        // Pass the new values to the next call
                        finalizeIntent(response, result, nextRetry, nextTimeout);
                    }
                    else {
                        onIdle();
                        setLock(false);
                        setError("Failed to verify transaction with the payment provider. Prevent a duplicate charge, please contact us.");
                    }
                }
                else {
                    onIdle();
                    setError(r.message);
                    setLock(false);
                }
            }).catch(function (err) {
                onIdle();
                setLock(false);
                setError("Network error. Please try later: " + err);
            }); 
        }, currentTimeout);
    };


    const onPayNow = ( e ) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            console.log('not loaded', stripe, elements)
            return;
        }



        if(lock)
        {
            console.error("Duplicate request");
            return;
        }
        setLock(true);
        onBusy();

        const signature = cart.signature;

        CartService.lock(cart, voucher, jwt,
            (rc)=>{
              console.log("returned cart", rc);
    
              if(signature !== rc.signature)
              {
                // something has changed - as the customer to double check
                setError("We have detected a change with your account. This may affect the price that you pay. Please re-check the cart. If you are happy to proceed, please try again");
                onIdle();
                setLock(false);
                return;
              }

              var chain = '';
      
              chain = chain + '&id=' + encodeURIComponent(cardid);
              chain = chain + '&fee=' + encodeURIComponent(price);
              chain = chain + '&currency=' + encodeURIComponent(currency);
              chain = chain + '&cart=' + encodeURIComponent(JSON.stringify(cart));
              chain = chain + '&voucher=' + encodeURIComponent(voucher); 
              chain = chain + '&invoice=' + encodeURIComponent(invoice); 
          
              setRetry(0);

              fetch(configuration.host + "iapi/ajax-charge-customer-card", {
                body: configuration.tokenname + '=' + configuration.tokenvalue + chain,
                method: "POST",
                credentials: 'include',
                headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                      "X-Requested-With": 'XMLHttpRequest',
                      "X_REQUESTED_WITH": 'xmlhttprequest',
                      'Authorization': 'Bearer ' + jwt                      
                  }
              })
              .then(res => {
                  return res.json();
              })
              .then(response => {
                  //console.log(data);
                  if(response.status === 'OK')
                  {
                      onIdle();
                      onComplete(true);
                  }
                  else
                  {
                     if(response.error_type === 'stripe')
                     {
                         var reason = '';
                         setInvoice(response.record);
                         onIdle();
      
                         const status = response?.intent?.object?.intent_status || response?.intent?.object?.error_code;

                         switch(status)
                         {
      
                            case 'amount_too_small':
                            {
                                setLock(false);
                                reason = 'The transaction value is below the minimum threshold.';
    
                            }
                            break;

                           case 'requires_action':
                           {

                            const url = window !== window.top ?  document.referrer || "*" : document.location.href;
                            const y = {message: 'rvRepostition', data:[], position: 'middle', height: document.getElementById('root').clientHeight };
                            window.parent.postMessage(JSON.stringify(y), url);    

                              onIdle();

                              var secret = response.intent.object.client_secret;
                              stripe.confirmCardPayment(secret, {
                                  payment_method: cardid,
                              }).then(function(result) {
                                // Handle result.error or result.paymentIntent
                                if(result.error)
                                {
                                  console.log('Set reason', result.error.message);
                                  setLock(false);
                                  setError(result.error.message);
                                  return;
               
                                }
                                else if(result.paymentIntent)
                                {
                                    finalizeIntent(response, result);
                                }
                                else
                                {
                                    console.error('Unexpected result state:', result);
                                    setError('Unexpected payment response. Please contact support.');
                                    setLock(false);
                                }
                                
                              }).catch(function(err) {  // Add catch block
                                console.error('confirmCardPayment failed:', err);
                                setError('Payment confirmation failed: ' + err.message);
                                setLock(false);
                              });
                           } 
                           break;
                           case 'authentication_required':
                           {

                            setLock(false);
                            reason = 'The card was declined as the transaction requires authentication by the card holder.';
                           }
                           break;
                           case 'approve_with_id':
                           {
                            setLock(false);
                            reason = 'The payment could not be authorised.';
                           }
                           break; 
                           case 'call_issuer':
                           {
                            setLock(false);
                            reason = 'The card has been declined for an unknown reason.';
                           }
                           break; 
                           case 'card_not_supported':
                           {
                            setLock(false);
                            reason = 'The card does not support this type of purchase.';
                           }
                           break;                     
                           case 'card_velocity_exceeded':
                           {
                            setLock(false);
                            reason = 'The customer has exceeded the balance or credit limit available on their card.';
                           }
                           break;  
                           case 'currency_not_supported':
                           {
                            setLock(false);
                            reason = 'The card does not support the specified currency.';
                           }
                           break;   
                           case 'do_not_honor':
                           {
                            setLock(false);
                            reason = 'The card has been declined by your bank.';
                           }
                           break;                       
                           case 'do_not_try_again':
                           {
                            setLock(false);
                            reason = 'The card has been declined for an unknown reason. Future attempts will be declined. Please check the details and try again. ';
                           }
                           break;                        
                           case 'duplicate_transaction':
                           {
                            setLock(false);
                            reason = 'A transaction with identical amount and credit card information was submitted very recently.';
                           }
                           break;                    
                           case 'expired_card':
                           {
                            setLock(false);
                            reason = 'The card has expired.';
                           }
                           break; 
                           case 'fraudulent':
                           {
                            setLock(false);
                            reason = 'The payment has been declined.';
                           }
                           break; 
       
                           case 'stolen_card':
                           case 'no_action_taken':
                           case 'merchant_blacklist':
                           case 'lost_card': 
                           case 'generic_decline':
                           {
                            setLock(false);
                            reason = 'The card has been declined. Please contact the card issuer';
                           }
                           break; 
                           
                           case 'invalid_number':
                           case 'incorrect_number':
                           {
                            setLock(false);
                            reason = '	The card number is incorrect.';
                           }
                           break; 
                           
                           case 'invalid_cvc':
                           case 'incorrect_cvc':
                           {
                            setLock(false);
                            reason = '	The card CVC number is incorrect.';
                           }
                           break;  
                           
                           case 'invalid_pin':
                           case 'incorrect_pin':
                           {
                            setLock(false);
                            reason = 'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.';
                           }
                           break; 
                           case 'incorrect_zip':
                           {
                            setLock(false);
                            reason = 'The ZIP or postal code is incorrect.';
                           }
                           break; 
                           
                           case 'withdrawal_count_limit_exceeded':
                           case 'insufficient_funds':
                           {
                            setLock(false);
                            reason = '	The card has insufficient funds to complete the purchase.';
                           }
                           break;  
                           
                           case 'new_account_information_available':
                           case 'invalid_account':
                           {
                            setLock(false);
                            reason = 'The card, or account the card is connected to, is invalid.';
                           }
                           break;  
                           case 'invalid_amount':
                           {
                            setLock(false);
                            reason = 'The payment amount is invalid, or exceeds the amount that is allowed.';
                           }
                           break;  
       
                           case 'invalid_expiry_year':
                           {
                            setLock(false);
                            reason = 'The expiration year invalid.';
       
                           }
                           break;
       
                           case 'issuer_not_available':
                           {
                            setLock(false);
                            reason = 'The card issuer could not be reached, so the payment could not be authorised.';
       
                           }
                           break;
       
                           case 'not_permitted':
                           {
                             reason = 'The payment is not permitted.';
       
                           }
                           break;
       
                           case 'online_or_offline_pin_required':
                           case 'offline_pin_required':
                           {
                            setLock(false);
                            reason = 'The card has been declined as it requires a PIN.';
       
                           }
                           break;
                           case 'pickup_card':
                           {
                            setLock(false);
                            reason = 'The card cannot be used to make this payment.';
       
                           }
                           break;
                           case 'pin_try_exceeded':
                           {
                            setLock(false);
                            reason = 'The allowable number of PIN tries has been exceeded.';
       
                           }
                           break;
                           case 'processing_error':
                           {
                            setLock(false);
                            reason = 'An error occurred while processing the card.';
       
                           }
                           break;
       
                           case 'try_again_later':
                           case 'reenter_transaction':
                           {
                            setLock(false);
                            reason = 'The payment could not be processed by the issuer for an unknown reason.';
                           }
                           break;
                           case 'restricted_card':
                           {
                            setLock(false);
                            reason = 'The card cannot be used to make this payment.';
                           }
                           break;
       
                           case 'revocation_of_authorization':
                           case 'revocation_of_all_authorizations':
                           case 'security_violation':
                           case 'service_not_allowed':
                           case 'stop_payment_order':
                           case 'transaction_not_allowed':
                           {
                            setLock(false);
                            reason = 'The card has been declined for an unknown reason.';
                           }
                           break;
                           case 'testmode_decline':
                           {
                            setLock(false);
                            reason = 'A Stripe test card number was used.';
                           }
                           break;
       
                           default:
                           {
                            setLock(false);
                            reason = 'Unknown error: ' + response.intent.object.decline_code;
       
                           }
                           break;
                         }
                         console.log('Set reason', reason);
                         setError(reason);
                     }
                     else
                     {
                         onIdle();
                         setError(response.message);
                     }
                  }
              }).catch(function (err) {
                  onIdle();
                  setError("Network error. Please check your network and refresh your browser.");
                  console.log('abort');
                  setLock(false);

              });  


            }, 
            ( e ) =>{
                console.log("error", e);
                setError("Please refresh your browser. Unable to confirm checkout: " + e);
                onIdle();
                setLock(false);
            }); 
        

    }



    const decode = (a) => {
      
        var s = window.atob(a);
        return s;
      }


    return (
        
        <div className="container-fluid no-gutters">    
            <form onSubmit={onPayNow}>              
                <div className="row">
                    <div className="col-12" >
                        <div className="form-group">
                            <label htmlFor="name">Card number</label>
                            <input  type="text" 
                                    className={"form-control " + styles.p__card}
                                    id="name"
                                    value={cardnumber}
                                    disabled={true} 
                                     />
                        </div>
                    </div>                                                                                                                                         
                </div>
                <div className="row">
                    <div className="col-4" >
                        <div className="form-group">
                            <label htmlFor="name">Expires</label>
                            <input  type="text" 
                                    className={"form-control " + styles.p__card}
                                    id="expires"
                                    value={expires}
                                    disabled={true} 
                                     />
                        </div>
                    </div>  
                </div>   
                <div className="row">
                    <div className="col-12">
                    {
                        terms.length > 0 && 
                        <div>
                                <fieldset class="form-group">
                                <h5 className={styles.p__detail}>Terms and conditions</h5>
                                {
                                terms.map( term => (
                                    <div key={"etid-"+term.tid} class="form-check">
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="checkbox" required={true} />
                                        {term.text}
                                    </label>
                                    </div>  
                                ))  
                                }
                                {
                                customer_terms.map( term => (
                                    <div class="form-check" key={"cust-tid-"+term.tid}>
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="checkbox" value={term.tid} required={true} /> 
                                        
                                    </label><div className="customerTerms">{parse(decode(term.text))}</div>
                                    </div>  
                                ))  
                                }


                                </fieldset>                   
                        </div>

                    }                        
                    </div>
                </div>             
                <div>
                    <button type="submit" disabled={disable || lock} className={"btn btn-sm btn-block " + styles.p__rVieBtn}>Pay {formatNumber(price, currency)}</button>
                </div>
            </form> 
            {
                error !== '' && (
                    <ChargeError onDone={onErrorClear} show={true} error={error} />
                )
            }   
        </div>

    );
  };
  
  const ExistingPaymentTypeForm = ({cardid, cardnumber, expires, price,currency, terms, customer_terms, disable, cart, voucher, onComplete, onIdle, onBusy}) => {
    const stripe_object = loadStripe(window.key, {stripeAccount: window.acct}); 
    return(
        <Elements stripe={stripe_object}>
            <ExistingPaymentTypeFormInner  cardid={cardid} cardnumber={cardnumber} expires={expires} price={price} currency={currency} terms={terms} customer_terms={customer_terms} disable={disable} cart={cart} voucher={voucher} onComplete={onComplete} onIdle={onIdle} onBusy={onBusy}/>
        </Elements>
    );


  }


  export default ExistingPaymentTypeForm;
  
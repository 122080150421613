import React from 'react';
import Layout from '../components/Layout';

import LogIn from '../components/shared/LogIn';
import {configuration, settings} from '../services/Checkout.settings.service';
import {CheckoutControllerContext, CHECKOUT_STAGE_PAYPASS} from '../contexts/CheckoutControllerContext';
import {CHECKOUT_STAGE_INITIALISE, CHECKOUT_STAGE_ACCOUNT, CHECKOUT_STAGE_ATTENDEES, CHECKOUT_STAGE_INFO, CHECKOUT_LOCK_EVENTS, CHECKOUT_STAGE_PAYCARD, CHECKOUT_STAGE_ANALYZE_PAYMENT_TYPE, CHECKOUT_STAGE_PURCHASE_COMPLETE, CHECKOUT_STAGE_CART_EMPTY, CHECKOUT_STAGE_FREE} from '../contexts/CheckoutControllerContext';
import Mailing from '../components/elements/mailing';
import Questionaire from '../components/elements/Questionaire';
import styles from './CheckoutController.module.scss';
import Moment from 'react-moment';
import {formatNumber} from '../helpers/utils';
import {BsFillUnlockFill,BsLockFill, BsFillTrashFill, BsThreeDots} from "react-icons/bs";
import CardForm from '../components/elements/CardForm';
import DiscountForm from '../components/elements/DiscountForm';
import ChoosePaymentTypeForm from '../components/elements/ChoosePaymentTypeForm';
import ExistingPaymentTypeForm from '../components/elements/ExistingPaymentTypeForm';
import PassPaymentTypeForm from '../components/elements/PassPaymentTypeForm';
import ReactTooltip from "react-tooltip";
import { useHistory } from 'react-router-dom';
import FreePaymentTypeForm from '../components/elements/FreePaymentTypeForm';
import {CartService} from '../services/cart.service'
import LogInCombined from '../components/shared/LogInCombined';
import WaitBlock from '../components/elements/WaitBlock';
import Attendees from '../components/elements/Attendees';
import { JwtContext } from '../contexts/JwtContext';
import { PassExpires } from '../components/elements/PassExpires';

const OBJECT_LOCKED_STATE_UNLOCKED = 0x00; 
const OBJECT_LOCKED_STATE_LOCKED   = 0x01; 
const OBJECT_LOCKED_STATE_ERROR    = 0x02; 

const template = {card:[], pass:[], free:[], subscription:[]};
const cardtempate = {'name': 'Enter new credit or debit card', id: '-1', expires: '', number:'', type: 'new'};

let c = [];


const CheckoutController = ({mode}) => {

        const [loggedIn, setLoggedIn] = React.useState(settings.isloggedIn);
        const {stage, setStage} = React.useContext(CheckoutControllerContext);
        const [previousStage, setPreviousStage] = React.useState(stage);
        const [locked, setLocked] = React.useState(false);
        const [cart, setCart] = React.useState(template);
        const [discount, setDiscount] = React.useState(0);
        const [adjustment, setAdjustment] = React.useState(0);
        const [voucher, setVoucher] = React.useState('');
        const [cards, setCards] = React.useState([cardtempate]);
        const [card, setCard] = React.useState(cards[0]);
        const [mailsubscribed, setMailSubscribed] = React.useState(settings.mailsubscribed);
        const [accountError, setAccountError] = React.useState('');
        const [attendees, setAttendees] = React.useState(0);
        const [attendeeList, setAttendeeList] = React.useState([]);
        const {storeJwt, jwt} = React.useContext(JwtContext);
        const [filters, setFilters] = React.useState({policy_event_book_others: false})
        const history = useHistory();
        const infoRequired = settings.mailactive || (!!settings.questionaire);
        const [terms, setTerms] = React.useState([]);
        const [qa, setQA] = React.useState([]);
        const questionaire = settings.questionaire;
        const [answers, setAnswers] = React.useState(settings.answers);
        const [country, setCountry] = React.useState('GB');
    

        const setStageState = ( n ) => {
            setPreviousStage(stage);
            setStage(n);
        }

        const postMessage =  (m) => {

            if (window === window.top) {
                console.log('This code should only run inside an iframe');
                return;
            }
            
            const targetOrigin = document.referrer || "*";
            window.parent.postMessage(m, targetOrigin);    
        }

        const onLockCart = ( c ) => {
//            let chain = '&cart=' + JSON.stringify( c );
//            chain       += '&v=' + encodeURIComponent(voucher) ;
            setAccountError('');

            c.customers = attendeeList;
            onBusy();
            CartService.lock(c, voucher, jwt, (rc)=>{
                //console.log("returned cart", rc);
                setCart(rc);
                onIdle();
                setStageState(CHECKOUT_STAGE_ANALYZE_PAYMENT_TYPE);

            }, 
            ( e ) =>{
                //console.log("error", e);
                onIdle();
                setStageState(previousStage); // roll back to the previous state
                setAccountError('Failed to validate some or all of the information that you have supplied. Reason: ' + e);
            });
        }


        React.useEffect(() => {

            const x = {message: 'rvReady', data:[], height: document.getElementById('root').clientHeight };
            console.log("Posting rvReady", x);
            postMessage(JSON.stringify(x));   

            switch(stage)
            {
                
                case CHECKOUT_STAGE_ACCOUNT:
                case CHECKOUT_STAGE_ATTENDEES:
                    const y = {message: 'rvRender', data:[], height: document.getElementById('root').clientHeight };
                    postMessage(JSON.stringify(y));                              
                break;

                case CHECKOUT_LOCK_EVENTS:
                    onLockCart(cart);
                break;

                case CHECKOUT_STAGE_ANALYZE_PAYMENT_TYPE:
                    const cash = cardTotal() + passTotal() + subscriptionTotal();
                    if(((cash === (discount + adjustment)) || (cash === 0)) && ((cardCount() > 0)||passTotal() > 0))
                    {
                        setStageState(CHECKOUT_STAGE_FREE)
                    }
                    else if((discount + adjustment) > cash)
                    {
                        // should never happen - but catch it anyway - should move to error state - to do. 
                        setStageState(CHECKOUT_STAGE_CART_EMPTY);
                    }
                    else if(cash > 0)
                    {
                        setStageState(CHECKOUT_STAGE_PAYCARD)
                    }
                    else if(passDebitTotal() > 0)
                    {
                        setStageState(CHECKOUT_STAGE_PAYPASS)
                    }
                    else if(((cash === (discount + adjustment)) || (cash === 0)) && passCreditCount() > 0)
                    {
                        // if it is a free pass - then allow checkout 
                        setStageState(CHECKOUT_STAGE_FREE)

                    }
                    else
                    {
                        setStageState(CHECKOUT_STAGE_CART_EMPTY)
                    }
                break;

                case CHECKOUT_STAGE_PURCHASE_COMPLETE:
                {
                    setCart(template);
                    const x = {message: 'rvFlush', data:[], height: document.getElementById('root').clientHeight };
                    postMessage(JSON.stringify(x));                              

                    const y = {message: 'rvRender', data:[], height: document.getElementById('root').clientHeight };
                    postMessage(JSON.stringify(y));                              

                    const z = {message: 'rvRepostition', data:[], position: 'top', height: document.getElementById('root').clientHeight };
                    postMessage(JSON.stringify(z));    

                }
                break

                default:
                {
                    console.log('[stage]', stage);
                }

            }

        }, [stage])

        const mapAnswersToQuestions = () => {
            if(!!questionaire)
            {
                let qs = questionaire.questions.map( q => {
                    let ans = answers.qas.find( a => a.id === q.id );
                    let o = Object.assign({}, q);
                    if(!!ans)
                    {
                        o.answer = ans.answer;
                    }
                    else
                    {
                        o.answer = { id: q.id, answer: ''}
                    }
                    return o;
                });
                setQA({'id':  questionaire.id, 'v': questionaire.v, 'qas': qs})
            }
        }


        React.useEffect(() => {
            mapAnswersToQuestions();
        }, [answers]);

        React.useEffect(() => {
            onRefreshParentSize();
        }, [questionaire]);


        React.useEffect(() => {

            const x = {message: 'rvLoaded', data:[], height: document.getElementById('root').clientHeight };
            postMessage(JSON.stringify(x));


        
             const onParentCommand = (event) => {
                 try{
                    const data = JSON.parse(event.data)
                    console.log("Child message", data)
                    switch(data.message)
                    {
                        case 'rvInitialize':
                            // send to the server and then respond with ready or error 
                            const t = Object.assign(template, data.data);
                            const a = onUpdateStates(t);
                 
                            setCountry(data?.country || 'GB'); 
                            var chain = '';
                            chain = '&token=' + data.token;
                            onBusy();
                            storeJwt(data.jwt);
                            fetch(configuration.host + "iapi/ajax-open-account", {
                                method: "POST",
                                credentials: 'include',
                                body: configuration.tokenname + '=' + configuration.tokenvalue + chain,
                                headers: {
                                    "Content-Type": "application/x-www-form-urlencoded",
                                    "X-Requested-With": 'XMLHttpRequest',
                                    "X_REQUESTED_WITH": 'xmlhttprequest',
                                    'Authorization': 'Bearer ' + data.jwt,
                                }
                            })
                            .then(res => {
                                return res.json();
                            })
                            .then(response => {
                                //console.log('Is logged in ', response);
                                if( response.status === 'OK')
                                {
                                    if(response.cards.length > 0)
                                    {
                                        let m = [];

                                        //m.push(cardtempate);
                                        m = response.cards.map( card => {
                                            const n = {name: 'Card: ' + card.number + '. Expires: ' + card.expires, id: card.id, number: card.number, expires: card.expires, type: 'registered'};
                                            return n;
                                        });

                                        setCards([cardtempate].concat(m));
                                    }

                                    onIdle();
                                    storeJwt(response.autoken);
                                    if(!!response.answers)
                                    {
                                        setAnswers(response.answers);
                                        //mapAnswersToQuestions();
                                        //setQA(response.answers);
                                    }
                                    const x = {message: 'rvReady', data:[], height: document.getElementById('root').clientHeight, jwt: response.autoken };
                                    //console.log("Posting rvReady", x);                                    
                                    postMessage(JSON.stringify(x));  
                                    
                                    const f = Object.assign({}, response.filters);
                                    setFilters(f);

                                    // if we cannot allow others to book - then skip this.
                                    if(!f.policy_event_book_others)
                                    {
                                        // OK we are not allowed to book others - so we need to walk throuugh and reset the person count
                                        // setup default attendee list
                                        const who = Array.from(Array(a), () => ({ id: 0, type: 'me', firstname: '', lastname: '', email: '', mobile: '' }));
                                        onAttendeesDefined( who );
                                        // now based on config select an screen
                                        if(infoRequired)
                                        {
                                            setStageState(CHECKOUT_STAGE_INFO);
                                        }
                                        else
                                        {
                                            setStageState(CHECKOUT_LOCK_EVENTS);
                                        }
                                    }
                                    else
                                    {
                                        setStageState(CHECKOUT_STAGE_ATTENDEES);
                                    }



                                }                
                                else
                                {
                                    // to do - process non -lock
                                    onIdle();
                                    const x = {message: 'rvReady', data:[], height: document.getElementById('root').clientHeight };
                                    console.log("Posting rvReady", x);
                                    postMessage(JSON.stringify(x));                              
                                    setStageState(CHECKOUT_STAGE_ACCOUNT);
                                    setLoggedIn(false);
                
                                }
                            }).catch(function (err) {
                                onIdle();
                                setLoggedIn(false);
                                setStageState(CHECKOUT_STAGE_ACCOUNT);
                                const x = {message: 'rvReady', data:[], height: document.getElementById('root').clientHeight };
                                //console.log("Posting rvReady", x);
                                postMessage(JSON.stringify(x));                              
    
                            });   
                        //    setState(CHECKOUT_CONTROLLER_STATE_RUNNING);
                        break;
    
    
    
                        default:
                            console.log("Unexpected parent command", data.message);
    
    
                    }
                 }
                 catch(e)
                 {

                 }

             };
             window.addEventListener("message", onParentCommand);


            return () => {
                window.removeEventListener("message", onParentCommand) 
            }
                    

        }, []);


        React.useEffect(() => { 
            let a = 0;
            setAdjustment(cart.card.reduce((a, transaction) => {
                a = a + transaction.objects.reduce((b, o) => {
                    return b + (isNaN(parseFloat(o.adjustment))? 0 : parseFloat(o.adjustment));
                }, 0);
                return a;
            }, 0));

            a = 0;
            let d = cart.card.reduce((a, transaction) => {
                a = a + transaction.objects.reduce((b, o) => {
                    return b + (isNaN(parseFloat(o.discount))? 0 : parseFloat(o.discount));
                }, 0);
                return a;
            }, 0); 
            
            a = 0;
            d += cart.pass.reduce((a, o) => {
                return a + (isNaN(parseFloat(o.discount))? 0 : parseFloat(o.discount));
            }, 0); 
            setDiscount(d);

        },[cart]);



        const onUpdateStates = ( objects ) => {

            let register = [];
            let instances = 0;

            let x = Object.assign({}, template);
            x.subscription = objects.subscription.map( object => {
                let o = Object.assign( {}, object);
                o.locked = OBJECT_LOCKED_STATE_UNLOCKED;
//                o.person = register.filter(id => id === object.id).length;
                o.person = 0;
                if(o.person > instances)
                {
                    instances = o.person;
                }
                register.push(object.id)
                return o;
            });
            x.card = objects.card.map( object => {
                let o = Object.assign( {}, object);
                o.locked = OBJECT_LOCKED_STATE_UNLOCKED;
                o.person = register.filter(id => id === object.id).length;
                if(o.person > instances)
                {
                    instances = o.person;
                }
                register.push(object.id)
                return o;
            });
            x.pass = objects.pass.map( pass => {
                let p = Object.assign( {}, pass);
                p.locked = OBJECT_LOCKED_STATE_UNLOCKED;
                p.transactions = pass.transactions.map( t => {
                    t.person = register.filter(id => id === t.id).length;
                    if(t.person > instances)
                    {
                        instances = t.person;
                    }                      
                    register.push(t.id)        
                    t.objects = t.objects.map( object => {
                        let o = Object.assign( {}, object);
                        o.locked = OBJECT_LOCKED_STATE_UNLOCKED;
                        o.lockedreason = '';                      
                        return o;     
                    });
                    return t;
                });
                return p;
            });

            //console.log('final cart', x);
            setCart(x);
            setAttendees(instances + 1);

           // const unique = [...new Map( settings.customer_terms.filter( t => { return isInCart( t.key_1 ) }).map(obj => [JSON.stringify(obj.tid), obj])).values()];
            
            
            const u =  settings.customer_terms.filter( t => { return isInCart( t.key_1 ) });
            const unique = [...new Map( u.map(obj => {
                const z = [JSON.stringify(obj.tid), obj];
                return z;
            })).values()];
            
            
            setTerms(unique);  
        
            return instances + 1;
        }


        const isErrorState = (  ) => {
            let s = !!cart.card.find( o => {
                return o.objects.find( object =>{
                    return object.locked === OBJECT_LOCKED_STATE_UNLOCKED || object.locked === OBJECT_LOCKED_STATE_ERROR;
                })
            });
            if(!s)
            {
                s = !!cart.pass.find( p => {
                    if(p.locked === OBJECT_LOCKED_STATE_UNLOCKED || p.locked === OBJECT_LOCKED_STATE_ERROR)
                    {
                        return true;
                    }
                    else
                    {
                        return !!p.transactions.find( t => {
                            return !!t.objects.find( o => {
                                return o.locked === OBJECT_LOCKED_STATE_UNLOCKED || o.locked === OBJECT_LOCKED_STATE_ERROR;
                            });
                        });
                    }
                });   
            }
            return s;
        }

        const onParentLogin = () => {
            const x = {message: 'rvLogin', data:[], height: document.getElementById('root').clientHeight };
            postMessage(JSON.stringify(x));  
        }


        const onParentLoggedIn = ( credentials ) => {
            const x = {message: 'rvLoggedIn', data:credentials, height: document.getElementById('root').clientHeight };
            postMessage(JSON.stringify(x));  
        }


        const onRefreshParentSize = () => {

            var h =  document.getElementById('root').clientHeight;

            //console.log('client height', h);

            const x = {message: 'rvReady', data:[], height: h };
            
            //console.log(x);

            postMessage(JSON.stringify(x));  

        }

        const SubTotal = ( discount, adjustment ) => {
        
            return subscriptionTotal() + cardTotal() + passTotal() - adjustment - discount;
        }


        const cardTotal = ( ) => {
            let ct = 0;
   
            
            ct = cart.card.reduce((ct, transaction) => {
                let t = 0;

                t = transaction.objects.reduce((t, product) => {
                    return (t + parseFloat(product.key_3.price) * parseInt(product.qty))    
                }, 0);

                return t + ct;
            }, 0);

            //console.log("total", ct);
            return ct;
        }

        const cardCount = ( ) => {
            let ct = 0;
   
            
            ct = cart.card.reduce((ct, transaction) => {
                let t = 0;
                t = transaction.objects.length;
                return t + ct;
            }, 0);
            return ct;
        }

        const passCreditCount = ( ) => {
            let pt = 0;
   
            pt = cart.pass.reduce((pt, product) => {
                if(product.mode === 'credit')
                {
                    return pt + 1;
                }
                else
                    return pt;
            }, 0);
  
            return pt;
        }


        const subscriptionTotal = () => {
            let ct = 0;  
            ct = cart.subscription.reduce((ct, transaction) => {
                return ct + parseFloat(transaction.price);
            }, 0);
            return ct;            
        }

        const passTotal = ( ) => {
            let pt = 0;
   
            pt = cart.pass.reduce((pt, product) => {
                if(product.mode === 'credit')
                {
                    const t = parseFloat(pt) +  parseFloat(product.price);
                    return t;
                }
                else
                    return parseFloat(pt);
            }, 0);
  
            return pt;
        }
       
        const passDebitTotal = ( ) => {
            let tx = 0;
            let pt = cart.pass.filter( pass => pass.mode === 'debit').reduce( (tx, pass) => {
                return tx + pass.transactions.length;
            },0);

            return pt;
        }        


        const onBusy = () =>{
            const x = {message: 'rvBusy', data:[], height: document.getElementById('root').clientHeight };
            console.log("Posting rvBusy", x);
            postMessage(JSON.stringify(x));  

        }

        const onIdle = () =>{
            
            const x = {message: 'rvIdle', data:[], height: document.getElementById('root').clientHeight };
            console.log("Posting rvIdle", x);
            postMessage(JSON.stringify(x));             
        }

        const onLoggedIn = ( credentials ) =>{

            const x = {message: 'rvLoggedIn', data:credentials, height: document.getElementById('root').clientHeight };
            postMessage(JSON.stringify(x));  
            setLoggedIn(true);
            setStageState(CHECKOUT_STAGE_ATTENDEES);
        }


        const onSetLockState = (v) => {
            setLocked(v);
        }

        const onPaymentComplete = ( v ) => {
         
            setStageState(CHECKOUT_STAGE_PURCHASE_COMPLETE);
        }


        const onApplyVoucher = ( v ) => {
            setVoucher( v );

        }

        React.useEffect(() => {
            if(voucher.length > 0 )
            {
                onLockCart( cart );
            }
        }, [voucher]);

        const isInCart = ( key ) => {
            var result = cart.card.find( t => {
                return !!t.objects.find( o => o.key_1 === key )
            });

            if(!!!result)
            {
                result = cart.pass.find( p => {
                    return !! p.transactions.find( t => {
                        return t.objects.find( o => o.key_1 === key )
                    })
                });
            }
            return !!result;
        }

        const onCartItemDelete = ( product ) => {
            let x = Object.assign({}, template);
            x.card = cart.card.map( t => {
                t.objects = t.objects.filter( object => {
                    if(object.key_1 !== product.key_1 || object.key_2 !== product.key_2 || object.key_3.id !== product.key_3.id)
                    {
                        // no match keep it 
                        return true;
                    }
                    return false; // match remove it
                });
                return t;
            }).filter(t => t.objects.length > 0);
            

            x.pass = cart.pass.map( pass => {
                let p = Object.assign( {}, pass);
                p.transactions = pass.transactions.map( t => {
                    t.objects = t.objects.filter( object => {
                        if(object.key_1 !== product.key_1 || object.key_2 !== product.key_2 || object.key_3.id !== product.key_3.id)
                        {
                            // no match keep it 
                            return true;
                        }
                        return false; // match remove it                          
                    });
                    return t;
                });
                return p;
            }).filter( pass => pass.transactions.length > 0);
            //setCart( x );

            onLockCart( x );
            // send a message back to the host
            const z = {message: 'rvUpdate', data: x, height: document.getElementById('root').clientHeight };
            postMessage(JSON.stringify(z));                              

        }


        const onCartPassDelete = (  product ) =>
        {
            let x = Object.assign({}, template);
            x.pass = cart.pass.filter( pass =>  pass.id !== product.id );
            onLockCart( x );
            // send a message back to the host
            const z = {message: 'rvUpdate', data: x, height: document.getElementById('root').clientHeight };
            postMessage(JSON.stringify(z));                              

        } 


        const onCartSubscriptionDelete = ( product ) => {
            let x = Object.assign({}, template);

            x.subscription = cart.subscription.filter( s =>  s.id !== product.id );
            onLockCart( x );
            // send a message back to the host
            const z = {message: 'rvUpdate', data: x, height: document.getElementById('root').clientHeight };
            postMessage(JSON.stringify(z));                              

        }


        const onChoosePaymentOption = ( cx ) =>{
            const s = cards.findIndex( c => c.id === cx );
            console.log('selected', cx, s);
            if(s >= 0)
            {
                console.log('selecting card', cards[s]);
                setCard( cards[s] );
            }
        }

        const fetchPassAvailability = (key_1) => {
            let search = cart.pass.find( (item ) => {
                return item.id === key_1;
            });
    
            if(!!search)
            {
                let used = 0;
                const credits_available = parseInt(search.credits);
    
                used = search.transactions.reduce((used, transaction) => {
                    let t = 0
                    t = transaction.objects.reduce((t, product) => t + (parseInt( product.key_3.credits ) * parseInt(product.qty)), 0);
                    return used+ t;
                }, 0);  
    
                return credits_available - used;            
            }
    
            return 0;
        }


        const onReturnToWebsite = () =>{
            history.push('/website');                             
        }
        const onGotoAccount = () =>{
            history.push('/account');        

        }

        const onAccountCriticalError = () => {
            setStageState(CHECKOUT_STAGE_ACCOUNT);
        }

        const onAccountError = (m) => {
            setAccountError(m);
        }

        const jsonEscape = (str) => {
            return str.replace(/'/g, '&#39;').replace(/"/, '&#34;').replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
        }
    

        const onAccountUpdate = () => {

            qa.qas = qa?.qas?.map( q => {
                q.text = jsonEscape( q.text );
                return  q;
            });



            var chain = 'mail=' + mailsubscribed;
            chain += '&questionaire=' + encodeURIComponent( JSON.stringify(qa) );
            setAccountError('');
            onBusy();
            fetch(configuration.host + "iapi/ajax-change-customer-account-preferences", {
                method: "POST",
                body: configuration.tokenname + '=' + configuration.tokenvalue + '&' + chain,
                credentials: 'include',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": 'XMLHttpRequest',
                    "X_REQUESTED_WITH": 'xmlhttprequest',
                    'Authorization': 'Bearer ' + jwt,
                }
            })
            .then(res => {
                return res.json();
            })
            .then(response => {
                //console.log(data);
                if(response.status === 'OK')
                {
                    onIdle();
                    setStageState(CHECKOUT_LOCK_EVENTS);                    
                }
                else
                {
                    onIdle();
                    onAccountError(response.message);
                }
            }).catch(function (err) {
                onIdle();
                onAccountCriticalError();
            }); 
        } 

        const onQAChange = ( qa ) => {
            setQA(qa);
        }


        const onMailChange = ( state ) => {
            setMailSubscribed(state);
        } 


        const onAttendeesDefined = ( attendee_list ) => {
            setAttendeeList( attendee_list )
            if(infoRequired)
            {
                setStageState(CHECKOUT_STAGE_INFO);
            }
            else
            {
                setStageState(CHECKOUT_LOCK_EVENTS);
            }

        }
        
        c.push('' + stage.toFixed(0) + ' ');

        switch(mode)
        {
            case 'proxy':
            {
                
                switch(stage)
                {
                        case CHECKOUT_STAGE_ATTENDEES:
                        return ( 
                            <Layout title="checkout" description={"checkout manager"} layout={'checkout'} >
                                {
                                    accountError.length > 0 &&
                                    <div class="alert  alert-danger mt-4">
                                        <strong>Oops!</strong> {accountError}
                                    </div>
                                }                          
                                <Attendees filters={filters} attendees={attendees} attendeelist={attendeeList} onChildIdle={onIdle} onChildBusy={onBusy} onReady={onAttendeesDefined} onRefreshSize={onRefreshParentSize}/>  
                            </Layout>
                        )                              

                        case CHECKOUT_STAGE_INITIALISE:
                        case CHECKOUT_STAGE_ACCOUNT:
                        return ( 
                            <Layout title="checkout" description={"checkout manager"} layout={'checkout'} >
                                <LogIn onChildIdle={onIdle} onChildBusy={onBusy} onReady={onLoggedIn}/>  

                            </Layout>
                        )                        
                        case CHECKOUT_STAGE_INFO:
                        return ( 
                            <Layout title="checkout" description={"checkout manager"}  layout={'checkout'} >
                                <form onSubmit={(e) => {e.preventDefault(); onAccountUpdate()}} autoComplete="off" >
                                    <Mailing onChange={onMailChange} state={mailsubscribed} />
                                    <Questionaire onChange={onQAChange} qa={qa} onSetLockState={onSetLockState} />

                                    {
                                        accountError.length > 0 &&
                                        <div class="alert  alert-danger mt-4">
                                            <strong>Oops!</strong> {accountError}
                                        </div>
                                    }
                                    
                                    <button type="submit" className={"btn btn-primary  btn-sm btn-block  " + styles.p__rVieBtn} disabled={locked}>Continue</button> 
                                </form>
                            </Layout>
                        )

                        
                        

                        case CHECKOUT_LOCK_EVENTS:
                        case CHECKOUT_STAGE_ANALYZE_PAYMENT_TYPE:
                        return ( 
                            <div></div>       
                        )                                                           



                        case CHECKOUT_STAGE_PAYCARD:
                        {
                            return (
                                <Layout title="checkout" description={"checkout manager"}  layout={'checkout'} >
                                    {
                                        accountError.length > 0 &&
                                        <div className="row no-gutters">
                                            <div className={"col-12 p-4 "}>
                                                <div class="alert  alert-danger mt-4">
                                                    <strong>Oops!</strong> {accountError}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row no-gutters">
                                        <div className={"col-12 col-md-6 p-4 " + styles.p__border}>
                                            <h4 className={"mb-4 "}>Cart</h4>
                                            <div className="container-fluid no-gutters">
                                                    {
                                                        cart.subscription.map( product => (
                                                            <React.Fragment>
                                                                <div className="row mb-3  mr-md-2">

                                                                    <div className="col-8">
                                                                        <h5 className={"mb-1 " + styles.p__cartItemName}>Subscription: {product.name}</h5>
                                                                        <PassExpires product={product} styles={styles} />                                                                    
                                                                        <p className={"mb-1 " + styles.p__iconDanger}>{product.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + product.lockedreason : ''}</p>
                                                                        
                                                                    </div>
                                                                    <div className="col-3">
                                            
                                                                        
                                                                        <p className={"mb-1 "}>
                                                                                {formatNumber(product.price, settings.currency)}
                                                                        </p>                                                                                  

                                                                    </div>
                                                                    <div className="col-1">
                                                                        {
                                                                            product.locked === OBJECT_LOCKED_STATE_UNLOCKED && <BsFillUnlockFill />
                                                                        }
                                                                        {
                                                                            product.locked === OBJECT_LOCKED_STATE_LOCKED && <BsLockFill />
                                                                        } 
                                                                        {
                                                                            product.locked === OBJECT_LOCKED_STATE_ERROR && 
                                                                            <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartSubscriptionDelete(product)}}/>                                                                           

                                                                        }                                                                                                                                                         
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>

                                                        ) )
                                                    }

                                                    {
                                                    cart.card.map( transaction => (
                                                        transaction.objects.map( product => (
                                                            <React.Fragment>
                                                            {
                                                                transaction.type !== "OBJECT_TYPE_VIDEO" &&
                                                                <div className="row mb-3 mr-md-2" key={'card-'+product.id}>
                                                                    <div className="col-8">
                                                                        <h5 className={"mb-1 " + styles.p__cartItemName}>Event: {product.name}</h5>
                                                                        <p className="mb-1">Date and time: {<Moment format="LLL">{product.date + "T" + product.time}</Moment>} </p>
                                                                        <p className="mb-1">Quantity: {product.qty }</p>
                                                                        {
                                                                            ((attendeeList.length > 0) && parseInt(transaction.person) >= 0 && attendeeList[parseInt(transaction.person)].firstname.length > 0 && attendeeList[parseInt(transaction.person)].type === 'other') && 
                                                                            <p className="mb-1">Attendee: {attendeeList[parseInt(transaction.person)].firstname}</p>
                                                                        }
                                                                        {
                                                                            ((attendeeList.length > 0) && parseInt(transaction.person) >= 0 && attendeeList[parseInt(transaction.person)].type === 'me') && 
                                                                            <p className="mb-1">Attendee: You</p>
                                                                        }                                                                        
                                                                        <p className={"mb-1 " + styles.p__iconDanger}>{product.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + product.lockedreason : ''}</p>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <React.Fragment>
                                                                        {
                                                                            ( (parseFloat(product.adjustment) > 0) || (parseFloat(product.discount) > 0)) &&
                                                                            
                                                                            <React.Fragment>
                                                                                <p className={"mb-1 " + styles.p__crossthrough}>
                                                                                    {formatNumber(product.key_3.price, settings.currency)}
                                                                                </p>
                                                                                <p className={"mb-1 "}>
                                                                                    {formatNumber((product.key_3.price - product.adjustment - product.discount), settings.currency)}
                                                                                </p>  
                                                                            </React.Fragment>
                                                                        }
                                                                        {
                                                                            ( (parseFloat(product.adjustment) <= 0) && (parseFloat(product.discount) <= 0)) &&
                                                                            <p className={"mb-1 "}>
                                                                                {formatNumber(product.key_3.price, settings.currency)}
                                                                            </p>
                                                                        }
                                                                        </React.Fragment>
                                                                                                                                                                                                                            
                                                                    </div>
                                                                    <div className="col-1">
                                                                        {
                                                                            product.locked === OBJECT_LOCKED_STATE_UNLOCKED &&
                                                                            <BsFillUnlockFill  data-for="toolTipUnlocked"/>

                                                                        }
                                                                        {
                                                                            product.locked === OBJECT_LOCKED_STATE_LOCKED &&
                                                                            <BsLockFill  data-for="toolTipLocked" />                                                                           
                                                                        }
                                                                        {
                                                                            product.locked === OBJECT_LOCKED_STATE_ERROR &&
                                                                            <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartItemDelete(product)}}/>                                                                           
                                                                        }

                                                                    </div>
                                                                </div> 
                                                            }
                                                            {
                                                                transaction.type === "OBJECT_TYPE_VIDEO" &&
                                                                <div className="row mb-3 mr-md-2" key={'card-'+product.id}>
                                                                    <div className="col-8">
                                                                        <h5 className={"mb-1 " + styles.p__cartItemName}>Video: {product.object.name}</h5>
                                                                        <p className="mb-1">Quantity: {product.qty }</p>
                                                                        {
                                                                            ((attendeeList.length > 0) && parseInt(transaction.person) >= 0 && attendeeList[parseInt(transaction.person)].firstname.length > 0 && attendeeList[parseInt(transaction.person)].type === 'other') && 
                                                                            <p className="mb-1">Viewer: {attendeeList[parseInt(transaction.person)].firstname}</p>
                                                                        }
                                                                        {
                                                                            ((attendeeList.length > 0) && parseInt(transaction.person) >= 0 && attendeeList[parseInt(transaction.person)].type === 'me') && 
                                                                            <p className="mb-1">Viewer: You</p>
                                                                        }                                                                        
                                                                        <p className={"mb-1 " + styles.p__iconDanger}>{transaction.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + product.lockedreason : ''}</p>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <React.Fragment>
                                                                        {
                                                                            ( (parseFloat(product.adjustment) > 0) || (parseFloat(product.discount) > 0)) &&
                                                                            
                                                                            <React.Fragment>
                                                                                <p className={"mb-1 " + styles.p__crossthrough}>
                                                                                    {formatNumber(product.key_3.price, settings.currency)}
                                                                                </p>
                                                                                <p className={"mb-1 "}>
                                                                                    {formatNumber((product.key_3.price - product.adjustment - product.discount), settings.currency)}
                                                                                </p>  
                                                                            </React.Fragment>
                                                                        }
                                                                        {
                                                                            ( (parseFloat(product.adjustment) <= 0) && (parseFloat(product.discount) <= 0)) &&
                                                                            <p className={"mb-1 "}>
                                                                                {formatNumber(product.key_3.price, settings.currency)}
                                                                            </p>
                                                                        }
                                                                        </React.Fragment>
                                                                                                                                                                                                                            
                                                                    </div>
                                                                    <div className="col-1">
                                                                        {
                                                                            product.locked === OBJECT_LOCKED_STATE_UNLOCKED &&
                                                                            <BsFillUnlockFill  data-for="toolTipUnlocked"/>

                                                                        }
                                                                        {
                                                                            product.locked === OBJECT_LOCKED_STATE_LOCKED &&
                                                                            <BsLockFill  data-for="toolTipLocked" />                                                                           
                                                                        }
                                                                        {
                                                                            product.locked === OBJECT_LOCKED_STATE_ERROR &&
                                                                            <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartItemDelete(product)}}/>                                                                           
                                                                        }

                                                                    </div>
                                                                </div> 
                                                            }
                                                                                                                           
                                                            </React.Fragment>

                                                        ))

                                                    ) )


                                                    } 

                                                    {
                                                        cart.pass.filter(item => ((item.transactions.length > 0 && item.mode === 'debit') || item.mode === 'credit')).map( product => (
                                                            <React.Fragment>
                                                                <div className="row mb-3  mr-md-2">

                                                                    <div className="col-8">
                                                                        <h5 className={"mb-1 " + styles.p__cartItemName}>Pass: {product.name}</h5>
                                                                        <PassExpires product={product} styles={styles} />                                                                    

                                                                        <p className={"mb-1 " + styles.p__iconDanger}>{product.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + product.lockedreason : ''}</p>
                                                                        
                                                                    </div>
                                                                    <div className="col-3">
                                                                        {
                                                                            product.mode === 'debit' && <div className={styles.p__payInfo}>Already purchased<div><small>(no additional charge)</small></div></div>

                                                                        }
                                                                        
                                                    
                                                                        {
                                                                            product.mode === 'credit' &&  (parseFloat(product.discount) > 0) && 
                                                                            <React.Fragment>
                                                                            <p className={"mb-1 " + styles.p__crossthrough}>
                                                                                {formatNumber(product.price, settings.currency)}
                                                                            </p>
                                                                            <p className={"mb-1 "}>
                                                                                {formatNumber(product.price - product.discount, settings.currency)}
                                                                            </p>                                                                                  
                                                                            </React.Fragment>

                                                                        }
                                                                        {
                                                                            product.mode === 'credit' &&  (parseFloat(product.discount) === 0) && 
                                                                            <React.Fragment>
                                                                            <p className={"mb-1 "}>
                                                                                {formatNumber(product.price, settings.currency)}
                                                                            </p>                                                                                  
                                                                            </React.Fragment>

                                                                        }

                                                                    </div>
                                                                    <div className="col-1">
                                                                        {
                                                                            product.mode === 'debit' && product.locked === OBJECT_LOCKED_STATE_UNLOCKED && <BsFillUnlockFill />
                                                                        }
                                                                        {
                                                                            product.mode === 'debit' && product.locked === OBJECT_LOCKED_STATE_LOCKED && <BsLockFill />
                                                                        }  
                                                                        {
                                                                            product.mode === 'debit' && product.locked === OBJECT_LOCKED_STATE_ERROR && 
                                                                            <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartPassDelete(product)}}/>                                                                           

                                                                        }                                                                              

                                                                        {
                                                                            product.mode === 'credit' && product.locked === OBJECT_LOCKED_STATE_UNLOCKED && <BsFillUnlockFill />
                                                                        }
                                                                        {
                                                                            product.mode === 'credit' && product.locked === OBJECT_LOCKED_STATE_LOCKED && <BsLockFill />
                                                                        } 
                                                                        {
                                                                            product.mode === 'credit' && product.locked === OBJECT_LOCKED_STATE_ERROR && 
                                                                            <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartPassDelete(product)}}/>                                                                           

                                                                        }                                                                                                                                                         
                                                                    </div>
                                                                </div>

                                                                {
                                                                    product.transactions.map( t => (
                                                                        t.objects.map(( p ) => (
                                                                            <div key={'pass-tx-' + p.id} className="row mb-2  mr-md-2 pl-2">
                                                                                {
                                                                                    t.type !== "OBJECT_TYPE_VIDEO" &&
                                                                                    <div className="col-5 ">
                                                                                        <h5 className={"mb-1 " + styles.p__cartItemName}>Event: {p.name}</h5>
                                                                                        <p className="mb-1 ">Date and time: {<Moment format="LLL">{p.date + "T" + p.time}</Moment>} </p>
                                                                                        <p className="mb-1 ">Quantity: {p.qty }</p>
                                                                                        {
                                                                                            ((attendeeList.length > 0) && parseInt(t.person) >= 0 && attendeeList[parseInt(t.person)].firstname.length > 0 && attendeeList[parseInt(t.person)].type === 'other') && 
                                                                                            <p className="mb-1">Attendee: {attendeeList[parseInt(t.person)].firstname}</p>
                                                                                        }
                                                                                        {
                                                                                            ((attendeeList.length > 0) && parseInt(t.person) >= 0 && attendeeList[parseInt(t.person)].type === 'me') && 
                                                                                            <p className="mb-1">Attendee: You</p>
                                                                                        } 
                                                                                        <p className={"mb-1 " + styles.p__iconDanger}>{p.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + p.lockedreason : ''}</p>
                                                                                    </div>                                                                                    
                                                                                }
                                                                                {
                                                                                    t.type === "OBJECT_TYPE_VIDEO" &&
                                                                                    <div className="col-5 ">
                                                                                        <h5 className={"mb-1 " + styles.p__cartItemName}>Video: {p.object.name}</h5>
                                                                                        <p className="mb-1 ">Quantity: {p.qty }</p>
                                                                                        {
                                                                                            ((attendeeList.length > 0) && parseInt(t.person) >= 0 && attendeeList[parseInt(t.person)].firstname.length > 0 && attendeeList[parseInt(t.person)].type === 'other') && 
                                                                                            <p className="mb-1">Viewer: {attendeeList[parseInt(t.person)].firstname}</p>
                                                                                        }
                                                                                        {
                                                                                            ((attendeeList.length > 0) && parseInt(t.person) >= 0 && attendeeList[parseInt(t.person)].type === 'me') && 
                                                                                            <p className="mb-1">Viewer: You</p>
                                                                                        } 
                                                                                        <p className={"mb-1 " + styles.p__iconDanger}>{p.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + p.lockedreason : ''}</p>
                                                                                    </div>                                                                                    
                                                                                }

                                                                                <div className="col-3">
                                                                                    <p className="mb-1">{parseInt(p.qty) * p.key_3.credits} credit(s)</p>
                                                                                </div>
                                                                                <div className="col-3">
                                                                                    {formatNumber(0, settings.currency)}
                                                                                </div>     
                                                                                                                                                       
                                                                                <div className="col-1">
                                                                                    {
                                                                                        p.locked === OBJECT_LOCKED_STATE_UNLOCKED &&
                                                                                        <BsFillUnlockFill  data-for="toolTipUnlocked"/>

                                                                                    }
                                                                                    {
                                                                                        p.locked === OBJECT_LOCKED_STATE_LOCKED &&
                                                                                        <BsLockFill  data-for="toolTipLocked" />                                                                           
                                                                                    }
                                                                                    {
                                                                                        p.locked === OBJECT_LOCKED_STATE_ERROR &&
                                                                                        <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartItemDelete(p)}}/>                                                                           
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    ))
                                                                }
                                                            </React.Fragment>

                                                        ) )
                                                    }
                                                    <div className="row mb-3  mr-md-2">

                                                        <div className="col-8">
                                                            <p className={"mb-1 " + styles.p__rVieBold}>Total</p>
                                                        </div>
                                                        <div className="col-4">
                                                            <p className={" " + styles.p__rVieBold}>{formatNumber(SubTotal(discount, adjustment), settings.currency)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-4">
                                                        <div className="col-12 align-self-center ">
                                                            <DiscountForm onChange={ onApplyVoucher } value={voucher}/>                                                        
                                                        </div>  
                                                    </div>                                                         
                                                    <div className="row mb-3 mr-md-2 ">
                                                        <div className="col-8">
                                                            <p className={"mb-1 " + styles.p__rVieBold}>Adjustments applied:</p>
                                                        </div>
                                                        <div className="col-3">
                                                            <p className={"mb-1 "}>{formatNumber(adjustment, settings.currency)}</p>
                                                        </div>
                                                        <div className="col-1">
                                                            <BsThreeDots />
                                                        </div>
                                                    </div>    
                                                    <div className="row mb-3 mr-md-2 ">
                                                        <div className="col-8">

                                                            <p className={"mb-1 " + styles.p__rVieBold}>Discount applied:</p>
                                                        </div>
                                                        <div className="col-3">
                                                            <p className={"mb-1 "}>{formatNumber(discount, settings.currency)}</p>
                                                        </div>
                                                        <div className="col-1">
                                                            <BsThreeDots />
                                                        </div>
                                                    </div>   
                                                    <div className="row mb-3  mr-md-2 pt-4 pb-4 border-top border-bottom">

                                                        <div className="col-8">
                                                            <h5 className={"mb-1 " + styles.p__cartItemName}>Total to pay now</h5>

                                                        </div>
                                                        <div className="col-3">
                                                            <p className={"mb-1 " + styles.p__rVieBold}>{formatNumber(SubTotal(discount,adjustment), settings.currency)}</p>
                                                        </div>
                                                        <div className="col-1">
                                                            <BsThreeDots     />
                                                        </div>
                                                    </div>                                                


                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 p-4">
                                            <h4 className={"mb-4 "}>Payment</h4>
                                            {
                                                cards.length > 0 &&
                                                <div className="container-fluid no-gutters">
                                                    <div className="row no-gutters">
                                                        <div className="col-12">
                                                            <h5>Step 1: Select a payment option</h5>
                                                            <p className={styles.p__rVieInfo}>You may either add a new card or choose a card that you have previously saved. Please select one of the options below:</p>
                                                            <ChoosePaymentTypeForm cards={cards} card={card} onChange={onChoosePaymentOption}/>
                                                        </div>
                                                    </div>
                                                    {
                                                        card.type === 'new' &&
                                                        <div className="row  no-gutters">
                                                            <div className="col-12">
                                                                <h5>Step 2: Enter credit or debit card</h5>
                                                                <p className={styles.p__rVieInfo}>Enter your credit or debit card details below and click pay when you are ready to proceed</p>
                                                                <CardForm price={SubTotal(discount, adjustment)} country={country} currency={settings.currency}  terms={settings.terms} customer_terms={terms} disable={isErrorState()} cart={cart} voucher={voucher} onComplete={onPaymentComplete} onIdle={onIdle} onBusy={onBusy}/>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        card.type === 'registered' && 
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h5>Step 2: Confirm credit or debit card</h5>
                                                                <p className={styles.p__rVieInfo}>Confirm that you have selected the correct card. This card will be charged the full value of the card. Click the 'pay' button below to proceed:</p>
                                                                <ExistingPaymentTypeForm cardid={card.id}  cardnumber={card.number} expires={card.expires} price={SubTotal(discount, adjustment)} currency={settings.currency} disable={isErrorState()} terms={settings.terms} customer_terms={terms} cart={cart} voucher={voucher} onComplete={onPaymentComplete} onIdle={onIdle} onBusy={onBusy} />
                                                            </div>
             
                                                        </div>
                                                    }

                                                </div>
                                            }
                                        </div>                                
                                    </div>
                                    <ReactTooltip id="toolTipUnlocked" place="top" effect="solid">
                                        Reservation cancelled. 
                                    </ReactTooltip>
                                    <ReactTooltip id="toolTipLocked" place="top" effect="solid">
                                        Reserved and pending payment
                                    </ReactTooltip>
                                    <ReactTooltip id="toolTipError" place="top" effect="solid">
                                        Unable to reserve 
                                    </ReactTooltip>                                    
                                </Layout>
                                
                            )

                        }


                        case CHECKOUT_STAGE_FREE:
                            {
                                return (
                                    <Layout title="checkout" description={"checkout manager"}  layout={'checkout'} >
                                        {
                                        accountError.length > 0 &&
                                        <div className="row no-gutters">
                                            <div className={"col-12 p-4 " + styles.p__border}>
                                                <div class="alert  alert-danger mt-4">
                                                    <strong>Oops!</strong> {accountError}
                                                </div>
                                            </div>
                                        </div>
                                        }                                        
                                        <div className="row no-gutters">
                                            <div className={"col-12 col-md-6 p-4 " + styles.p__border}>
                                                <h4 className={"mb-4 "}>Cart</h4>
                                                <div className="container-fluid no-gutters">
                                                {
    
                                                            cart.card.map( transaction => (
                                                                transaction.objects.map( product => (
                                                                    <React.Fragment>
                                                                    {
                                                                    transaction.type !== "OBJECT_TYPE_VIDEO" &&
                                                                    <div className="row mb-3 mr-md-2" key={'card-'+product.id}>
                                                                        <div className="col-8">
                                                                            <h5 className={"mb-1 " + styles.p__cartItemName}>Event: {product.name}</h5>
                                                                            <p className="mb-1">Date and time: {<Moment format="LLL">{product.date + "T" + product.time}</Moment>} </p>
                                                                            <p className="mb-1">Quantity: {product.qty }</p>
                                                                            <p className={"mb-1 " + styles.p__iconDanger}>{product.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + product.lockedreason : ''}</p>
                                                                            {
                                                                                ((attendeeList.length > 0) && parseInt(transaction.person) >= 0 && attendeeList[parseInt(transaction.person)].firstname.length > 0 && attendeeList[parseInt(transaction.person)].type === 'other') && 
                                                                                <p className="mb-1">Attendee: {attendeeList[parseInt(transaction.person)].firstname}</p>
                                                                            }
                                                                            {
                                                                                ((attendeeList.length > 0) && parseInt(transaction.person) >= 0 && attendeeList[parseInt(transaction.person)].type === 'me') && 
                                                                                <p className="mb-1">Attendee: You</p>
                                                                            }                                                                         
                                                                        
                                                                        </div>
                                                                        <div className="col-3">
                                                                            <React.Fragment>
                                                                            {
                                                                                ( (parseFloat(product.adjustment) > 0) || (parseFloat(product.discount) > 0)) &&
                                                                                
                                                                                <React.Fragment>
                                                                                    <p className={"mb-1 " + styles.p__crossthrough}>
                                                                                        {formatNumber(product.key_3.price, settings.currency)}
                                                                                    </p>
                                                                                    <p className={"mb-1 "}>
                                                                                        {formatNumber(product.key_3.price - product.adjustment - product.discount, settings.currency)}
                                                                                    </p>  
                                                                                </React.Fragment>
                                                                            }
                                                                            {
                                                                                ( (parseFloat(product.adjustment) <= 0) && (parseFloat(product.discount) <= 0)) &&
                                                                                <p className={"mb-1 "}>
                                                                                    {formatNumber(product.key_3.price, settings.currency)}
                                                                                </p>
                                                                            }
                                                                            </React.Fragment>
                                                                                                                                                                                                                               
                                                                        </div>
                                                                        <div className="col-1">
                                                                            {
                                                                                product.locked === OBJECT_LOCKED_STATE_UNLOCKED &&
                                                                                <BsFillUnlockFill  data-for="toolTipUnlocked"/>
    
                                                                            }
                                                                            {
                                                                                product.locked === OBJECT_LOCKED_STATE_LOCKED &&
                                                                                <BsLockFill  data-for="toolTipLocked" />                                                                           
                                                                            }
                                                                            {
                                                                                product.locked === OBJECT_LOCKED_STATE_ERROR &&
                                                                                <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartItemDelete(product)}}/>                                                                           
                                                                            }
    
                                                                        </div>
                                                                        
                                                                    </div>                                                                        
                                                                    }  
                                                                    {
                                                                        transaction.type === "OBJECT_TYPE_VIDEO" &&
                                                                        <div className="row mb-3 mr-md-2" key={'card-'+product.id}>
                                                                            <div className="col-8">
                                                                                <h5 className={"mb-1 " + styles.p__cartItemName}>Video: {product.object.name}</h5>
                                                                                <p className="mb-1">Quantity: {product.qty }</p>
                                                                                {
                                                                                    ((attendeeList.length > 0) && parseInt(transaction.person) >= 0 && attendeeList[parseInt(transaction.person)].firstname.length > 0 && attendeeList[parseInt(transaction.person)].type === 'other') && 
                                                                                    <p className="mb-1">Viewer: {attendeeList[parseInt(transaction.person)].firstname}</p>
                                                                                }
                                                                                {
                                                                                    ((attendeeList.length > 0) && parseInt(transaction.person) >= 0 && attendeeList[parseInt(transaction.person)].type === 'me') && 
                                                                                    <p className="mb-1">Viewer: You</p>
                                                                                }                                                                        
                                                                                <p className={"mb-1 " + styles.p__iconDanger}>{transaction.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + product.lockedreason : ''}</p>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <React.Fragment>
                                                                                {
                                                                                    ( (parseFloat(product.adjustment) > 0) || (parseFloat(product.discount) > 0)) &&
                                                                                    
                                                                                    <React.Fragment>
                                                                                        <p className={"mb-1 " + styles.p__crossthrough}>
                                                                                            {formatNumber(product.key_3.price, settings.currency)}
                                                                                        </p>
                                                                                        <p className={"mb-1 "}>
                                                                                            {formatNumber((product.key_3.price - product.adjustment - product.discount), settings.currency)}
                                                                                        </p>  
                                                                                    </React.Fragment>
                                                                                }
                                                                                {
                                                                                    ( (parseFloat(product.adjustment) <= 0) && (parseFloat(product.discount) <= 0)) &&
                                                                                    <p className={"mb-1 "}>
                                                                                        {formatNumber(product.key_3.price, settings.currency)}
                                                                                    </p>
                                                                                }
                                                                                </React.Fragment>
                                                                                                                                                                                                                                    
                                                                            </div>
                                                                            <div className="col-1">
                                                                                {
                                                                                    product.locked === OBJECT_LOCKED_STATE_UNLOCKED &&
                                                                                    <BsFillUnlockFill  data-for="toolTipUnlocked"/>

                                                                                }
                                                                                {
                                                                                    product.locked === OBJECT_LOCKED_STATE_LOCKED &&
                                                                                    <BsLockFill  data-for="toolTipLocked" />                                                                           
                                                                                }
                                                                                {
                                                                                    product.locked === OBJECT_LOCKED_STATE_ERROR &&
                                                                                    <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartItemDelete(product)}}/>                                                                           
                                                                                }

                                                                            </div>
                                                                        </div> 
                                                                    }
                                                                    </React.Fragment>


                                                                ))
    
                                                            ) )
    
    
                                                            } 
    
                                                            {
                                                                cart.pass.filter(item => ((item.transactions.length > 0 && item.mode === 'debit') || item.mode === 'credit')).map( product => (
                                                                    <React.Fragment>
                                                                        
                                                                        <div className="row mb-3  mr-md-2">
    
                                                                            <div className="col-8">
                                                                                <h5 className={"mb-1 " + styles.p__cartItemName}>Pass: {product.name}</h5>
                                                                                <PassExpires product={product} styles={styles} />                                                                    

                                                                                {
                                                                                    product.mode === 'debit' && product.locked === OBJECT_LOCKED_STATE_ERROR && 
                                                                                    <p className={"mb-1 " + styles.p__iconDanger}>{product.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + product.lockedreason : ''}</p>
                                                                                }
                                                                                {
                                                                                    product.mode === 'credit' && product.locked === OBJECT_LOCKED_STATE_ERROR && 
                                                                                    <p className={"mb-1 " + styles.p__iconDanger}>{product.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + product.lockedreason : ''}</p>
                                                                                }

                                                                            </div>
                                                                            <div className="col-3">
                                                                                {
                                                                                    product.mode === 'debit' && <div className={styles.p__payInfo}>Already purchased<div><small>(no additional charge)</small></div></div>
    
                                                                                }
                                                                                
                                                            
                                                                                {
                                                                                    product.mode === 'credit' &&  (parseFloat(product.discount) > 0) && 
                                                                                    <React.Fragment>
                                                                                    <p className={"mb-1 " + styles.p__crossthrough}>
                                                                                        {formatNumber(product.price, settings.currency)}
                                                                                    </p>
                                                                                    <p className={"mb-1 "}>
                                                                                        {formatNumber(product.price - product.discount, settings.currency)}
                                                                                    </p>                                                                                  
                                                                                    </React.Fragment>
    
                                                                                }
                                                                                {
                                                                                    product.mode === 'credit' &&  (parseFloat(product.discount) === 0) && 
                                                                                    <React.Fragment>
                                                                                    <p className={"mb-1 "}>
                                                                                        {formatNumber(product.price, settings.currency)}
                                                                                    </p>                                                                                  
                                                                                    </React.Fragment>
    
                                                                                }
    
                                                                            </div>
                                                                            <div className="col-1">
                                                                                
                                                                                {
                                                                                    product.mode === 'debit' && product.locked === OBJECT_LOCKED_STATE_UNLOCKED && <BsFillUnlockFill />
                                                                                }
                                                                                {
                                                                                    product.mode === 'debit' && product.locked === OBJECT_LOCKED_STATE_LOCKED && <BsLockFill />
                                                                                }  
                                                                                {
                                                                                    product.mode === 'debit' && product.locked === OBJECT_LOCKED_STATE_ERROR && 
                                                                                    (
                                                                                        <React.Fragment>
                                                                                            <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartPassDelete(product)}}/>                                                                           
                                                                                        </React.Fragment>
                                                                                    )

                                                                                }                                                                              
    
                                                                                {
                                                                                    product.mode === 'credit' && product.locked === OBJECT_LOCKED_STATE_UNLOCKED && <BsFillUnlockFill />
                                                                                }
                                                                                {
                                                                                    product.mode === 'credit' && product.locked === OBJECT_LOCKED_STATE_LOCKED && <BsLockFill />
                                                                                } 
                                                                                {
                                                                                    product.mode === 'credit' && product.locked === OBJECT_LOCKED_STATE_ERROR && 
                                                                                    (
                                                                                        <React.Fragment>
                                                                                            <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartPassDelete(product)}}/>                                                                           
                                                                                        </React.Fragment>
                                                                                    )
                                                                                }                                                                                                                                                         
                                                                            </div>
                                                                        </div>
    
                                                                        {
                                                                            product.transactions.map( t => (
                                                                                t.objects.map(( p ) => (
                                                                                    <div key={'pass-tx-' + p.id} className="row mb-2  mr-md-2 pl-2">
                                                                                        {
                                                                                            t.type !== "OBJECT_TYPE_VIDEO" &&
                                                                                            <div className="col-5 ">
                                                                                                <h5 className={"mb-1 " + styles.p__cartItemName}>Event: {p.name}</h5>
                                                                                                <p className="mb-1 ">Date and time: {<Moment format="LLL">{p.date + "T" + p.time}</Moment>} </p>
                                                                                                <p className="mb-1 ">Quantity: {p.qty }</p>
                                                                                                {
                                                                                                ((attendeeList.length > 0) && parseInt(t.person) >= 0 && attendeeList[parseInt(t.person)].firstname.length > 0 && attendeeList[parseInt(t.person)].type === 'other') && 
                                                                                                <p className="mb-1">Attendee: {attendeeList[parseInt(t.person)].firstname}</p>
                                                                                                }
                                                                                                {
                                                                                                    ((attendeeList.length > 0) && parseInt(t.person) >= 0 && attendeeList[parseInt(t.person)].type === 'me') && 
                                                                                                    <p className="mb-1">Attendee: You</p>
                                                                                                }
                                                                                                <p className={"mb-1 " + styles.p__iconDanger}>{p.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + p.lockedreason : ''}</p>

                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            t.type === "OBJECT_TYPE_VIDEO" &&
                                                                                            <div className="col-5 ">
                                                                                                <h5 className={"mb-1 " + styles.p__cartItemName}>Video: {p.object.name}</h5>
                                                                                                <p className="mb-1 ">Quantity: {p.qty }</p>
                                                                                                {
                                                                                                ((attendeeList.length > 0) && parseInt(t.person) >= 0 && attendeeList[parseInt(t.person)].firstname.length > 0 && attendeeList[parseInt(t.person)].type === 'other') && 
                                                                                                <p className="mb-1">Attendee: {attendeeList[parseInt(t.person)].firstname}</p>
                                                                                                }
                                                                                                {
                                                                                                    ((attendeeList.length > 0) && parseInt(t.person) >= 0 && attendeeList[parseInt(t.person)].type === 'me') && 
                                                                                                    <p className="mb-1">Attendee: You</p>
                                                                                                }
                                                                                                <p className={"mb-1 " + styles.p__iconDanger}>{p.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + p.lockedreason : ''}</p>

                                                                                            </div>
                                                                                        }                                                                                        
                                                                                        <div className="col-3">
                                                                                            <p className="mb-1">{p.key_3.credits} credit(s)</p>
                                                                                        </div>
                                                                                        <div className="col-3">
                                                                                            {formatNumber(0, settings.currency)}
                                                                                        </div>                                                                            
                                                                                        <div className="col-1">
                                                                                            {
                                                                                                p.locked === OBJECT_LOCKED_STATE_UNLOCKED &&
                                                                                                <BsFillUnlockFill  data-for="toolTipUnlocked"/>
    
                                                                                            }
                                                                                            {
                                                                                                p.locked === OBJECT_LOCKED_STATE_LOCKED &&
                                                                                                <BsLockFill  data-for="toolTipLocked" />                                                                           
                                                                                            }
                                                                                            {
                                                                                                p.locked === OBJECT_LOCKED_STATE_ERROR &&
                                                                                                <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartItemDelete(p)}}/>                                                                           
                                                                                            }
    
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            ))
                                                                        }
                                                                    </React.Fragment>
    
                                                                ) )
                                                            }
                                                            <div className="row mb-3  mr-md-2">
    
                                                                <div className="col-8">
                                                                    <p className={"mb-1 " + styles.p__rVieBold}>Total</p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <p className={" " + styles.p__rVieBold}>{formatNumber(SubTotal(discount, adjustment), settings.currency)}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-12 align-self-center ">
                                                                    <DiscountForm onChange={ onApplyVoucher } value={voucher}/>                                                        
                                                                </div>  
                                                            </div>                                                         
                                                            <div className="row mb-3 mr-md-2 ">
                                                                <div className="col-8">
                                                                    <p className={"mb-1 " + styles.p__rVieBold}>Adjustments applied:</p>
                                                                </div>
                                                                <div className="col-3">
                                                                    <p className={"mb-1 "}>{formatNumber(adjustment, settings.currency)}</p>
                                                                </div>
                                                                <div className="col-1">
                                                                    <BsThreeDots />
                                                                </div>
                                                            </div>    
                                                            <div className="row mb-3 mr-md-2 ">
                                                                <div className="col-8">
    
                                                                    <p className={"mb-1 " + styles.p__rVieBold}>Discount applied:</p>
                                                                </div>
                                                                <div className="col-3">
                                                                    <p className={"mb-1 "}>{formatNumber(discount, settings.currency)}</p>
                                                                </div>
                                                                <div className="col-1">
                                                                    <BsThreeDots />
                                                                </div>
                                                            </div>   
                                                            <div className="row mb-3  mr-md-2 pt-4 pb-4 border-top border-bottom">
    
                                                                <div className="col-8">
                                                                    <h5 className={"mb-1 " + styles.p__cartItemName}>Total to pay now</h5>
    
                                                                </div>
                                                                <div className="col-3">
                                                                    <p className={"mb-1 " + styles.p__rVieBold}>{formatNumber(SubTotal(discount,adjustment), settings.currency)}</p>
                                                                </div>
                                                                <div className="col-1">
                                                                    <BsThreeDots     />
                                                                </div>
                                                            </div>                                                
    
    
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 p-4">
                                                <h4 className={"mb-4 "}>Book now</h4>
                                                <p className={styles.p__rVieInfo}>Your selected event(s) and/or pass(es) have been discounted and/or adjusted. There is nothing to pay.</p>
                                                {
                                                    cards.length > 0 &&
                                                    <div className="container-fluid no-gutters">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h5>Confirm and complete booking</h5>
                                                                <p className={styles.p__rVieInfo}>To proceed with the booking, please read carefully the information below and then click the book now.</p>
                                                                <FreePaymentTypeForm currency={settings.currency}  disable={isErrorState()} onComplete={onPaymentComplete} onIdle={onIdle} onBusy={onBusy}  cart={cart} voucher={voucher} terms={settings.terms} customer_terms={terms} />
                                                            </div>
                                                        </div>


                                                    </div>
                                                }
                                            </div>                                
                                        </div>
                                        <ReactTooltip id="toolTipUnlocked" place="top" effect="solid">
                                            Reservation cancelled. 
                                        </ReactTooltip>
                                        <ReactTooltip id="toolTipLocked" place="top" effect="solid">
                                            Reserved and pending payment
                                        </ReactTooltip>
                                        <ReactTooltip id="toolTipError" place="top" effect="solid">
                                            Unable to reserve 
                                        </ReactTooltip>                                    
                                    </Layout>
                                    
                                )
    
                            }

                        case CHECKOUT_STAGE_PAYPASS:
                        {
                            return (
                                <Layout title="checkout" description={"checkout manager"}  layout={'checkout'} >
                                    {
                                        accountError.length > 0 &&
                                        <div className="row no-gutters">
                                            <div className={"col-12 p-4 " + styles.p__border}>
                                                <div class="alert  alert-danger mt-4">
                                                    <strong>Oops!</strong> {accountError}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    
                                    
                                    <div className="row no-gutters">
                                        <div className={"col-12 col-md-6 p-4 " + styles.p__border}>
                                            <h4 className={"mb-4 "}>Cart</h4>
                                            <div className="container-fluid">
                                            {
                                                        cart.card.map( transaction => (
                                                            transaction.objects.map( product => ( 
                                                                <div className="row mb-3 mr-md-2" key={'card-'+product.id}>
                                                                    <div className="col-8">
                                                                        <h5 className={"mb-1 " + styles.p__cartItemName}>Event: {product.name}</h5>
                                                                        <p className="mb-1">Date and time: {<Moment format="LLL">{product.date + "T" + product.time}</Moment>} </p>
                                                                        <p className="mb-1">Quantity: {product.qty }</p>
                                                                        {
                                                                            ((attendeeList.length > 0) && parseInt(transaction.person) >= 0 && attendeeList[parseInt(transaction.person)].firstname.length > 0 && attendeeList[parseInt(transaction.person)].type === 'other') && 
                                                                            <p className="mb-1">Attendee: {attendeeList[parseInt(transaction.person)].firstname}</p>
                                                                        }
                                                                        {
                                                                            ((attendeeList.length > 0) && parseInt(transaction.person) >= 0 && attendeeList[parseInt(transaction.person)].type === 'me') && 
                                                                            <p className="mb-1">Attendee: You</p>
                                                                        } 
                                                                        <p className={"mb-1 " + styles.p__iconDanger}>{product.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + product.lockedreason : ''}</p>

                                                                    </div>
                                                                    <div className="col-3">
                                                                        <p className="mb-1">{formatNumber(product.key_3.price, settings.currency)}</p>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <BsFillUnlockFill />
                                                                    </div>
                                                                </div>
                                                            ))))
                                                        } 

                                                        {
                                                            cart.pass.filter(item => ((item.transactions.length > 0 && item.mode === 'debit') || item.mode === 'credit')).map( product => (
                                                                <React.Fragment>
                                                                    <div className="row mb-3  mr-md-2">

                                                                        <div className="col-8">
                                                                            <h5 className={"mb-1 " + styles.p__cartItemName}>Pass: {product.name}</h5>
                                                                            <PassExpires product={product} styles={styles} />                                                                    

                                                                            {
                                                                                product.mode === 'debit' && product.locked === OBJECT_LOCKED_STATE_ERROR && 
                                                                                <p className={"mb-1 " + styles.p__iconDanger}>{product.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + product.lockedreason : ''}</p>
                                                                            }
                                                                            {
                                                                                product.mode === 'credit' && product.locked === OBJECT_LOCKED_STATE_ERROR && 
                                                                                <p className={"mb-1 " + styles.p__iconDanger}>{product.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + product.lockedreason : ''}</p>
                                                                            }
                                                                            
                                                                        </div>
                                                                        <div className="col-3">
                                                                            {
                                                                                product.mode === 'debit' && <div className={styles.p__payInfo}>Already purchased<div><small>(no additional charge)</small></div></div>

                                                                            }
                                                                            {
                                                                                product.mode === 'credit' &&   <p className="mb-1">{formatNumber(product.price, settings.currency)}</p>

                                                                            }
                                                                        </div>
                                                                        <div className="col-1">
                                                                            {
                                                                                product.mode === 'debit' && product.locked === OBJECT_LOCKED_STATE_UNLOCKED && <BsFillUnlockFill />
                                                                            }
                                                                            {
                                                                                product.mode === 'debit' && product.locked === OBJECT_LOCKED_STATE_LOCKED && <BsLockFill />
                                                                            }  
                                                                            {
                                                                                product.mode === 'debit' && product.locked === OBJECT_LOCKED_STATE_ERROR && 
                                                                                <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartPassDelete(product)}}/>                                                                           

                                                                            }                                                                              

                                                                            {
                                                                                product.mode === 'credit' && product.locked === OBJECT_LOCKED_STATE_UNLOCKED && <BsFillUnlockFill />
                                                                            }
                                                                            {
                                                                                product.mode === 'credit' && product.locked === OBJECT_LOCKED_STATE_LOCKED && <BsLockFill />
                                                                            } 
                                                                            {
                                                                                product.mode === 'credit' && product.locked === OBJECT_LOCKED_STATE_ERROR && 
                                                                                <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartPassDelete(product)}}/>                                                                           

                                                                            } 
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        product.transactions.map( t => (
                                                                            t.objects.map(( p ) => ( 
                                                                            <div className="row mb-2  mr-md-2 pl-2">
                                                                                {
                                                                                    t.type !== "OBJECT_TYPE_VIDEO" &&                                                                                
                                                                                    <div className="col-5 ">
                                                                                        <h5 className={"mb-1 " + styles.p__cartItemName}>Event: {p.name}</h5>
                                                                                        <p className="mb-1 ">Date and time: {<Moment format="LLL">{p.date + "T" + p.time}</Moment>} </p>
                                                                                        <p className="mb-1 ">Quantity: {p.qty }</p>
                                                                                        {
                                                                                            ((attendeeList.length > 0) && parseInt(t.person) >= 0 && attendeeList[parseInt(t.person)].firstname.length > 0 && attendeeList[parseInt(t.person)].type === 'other') && 
                                                                                            <p className="mb-1">Attendee: {attendeeList[parseInt(t.person)].firstname}</p>
                                                                                        }
                                                                                        {
                                                                                            ((attendeeList.length > 0) && parseInt(t.person) >= 0 && attendeeList[parseInt(t.person)].type === 'me') && 
                                                                                            <p className="mb-1">Attendee: You</p>
                                                                                        }
                                                                                        <p className={"mb-1 " + styles.p__iconDanger}>{p.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + p.lockedreason : ''}</p>

                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    t.type === "OBJECT_TYPE_VIDEO" &&                                                                                
                                                                                    <div className="col-5 ">
                                                                                        <h5 className={"mb-1 " + styles.p__cartItemName}>Video: {p.object.name}</h5>
                                                                                        <p className="mb-1 ">Quantity: {p.qty }</p>
                                                                                        {
                                                                                            ((attendeeList.length > 0) && parseInt(t.person) >= 0 && attendeeList[parseInt(t.person)].firstname.length > 0 && attendeeList[parseInt(t.person)].type === 'other') && 
                                                                                            <p className="mb-1">Attendee: {attendeeList[parseInt(t.person)].firstname}</p>
                                                                                        }
                                                                                        {
                                                                                            ((attendeeList.length > 0) && parseInt(t.person) >= 0 && attendeeList[parseInt(t.person)].type === 'me') && 
                                                                                            <p className="mb-1">Attendee: You</p>
                                                                                        }
                                                                                        <p className={"mb-1 " + styles.p__iconDanger}>{p.locked === OBJECT_LOCKED_STATE_ERROR?'Error. Please remove from cart: ' + p.lockedreason : ''}</p>

                                                                                    </div>
                                                                                }                                                                                
                                                                                <div className="col-3">
                                                                                    <p className="mb-1">{p.key_3.credits} credit(s)</p>
                                                                                </div>
                                                                                <div className="col-3">
                                                                                    {formatNumber(0, settings.currency)}
                                                                                </div>                                                                            
                                                                                <div className="col-1">
                                                                                       {
                                                                                            p.locked === OBJECT_LOCKED_STATE_UNLOCKED &&
                                                                                            <BsFillUnlockFill  data-for="toolTipUnlocked"/>

                                                                                        }
                                                                                        {
                                                                                            p.locked === OBJECT_LOCKED_STATE_LOCKED &&
                                                                                            <BsLockFill  data-for="toolTipLocked" />                                                                           
                                                                                        }
                                                                                        {
                                                                                            p.locked === OBJECT_LOCKED_STATE_ERROR &&
                                                                                            <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartItemDelete(p)}}/>                                                                           
                                                                                        }                                                                                
                                                                                </div>
                                                                            </div>
                                                                        ))))
                                                                    }
                                                                </React.Fragment>

                                                            ) )
                                                        }
                                                            

                                                        <div className="row mb-3  mr-md-2 pt-4 pb-4 border-top border-bottom">

                                                            <div className="col-8">
                                                                <h5 className={"mb-1 " + styles.p__cartItemName}>Total to pay now</h5>

                                                            </div>
                                                            <div className="col-3">
                                                                <p className={"mb-1 " + styles.p__rVieBold}>{formatNumber(SubTotal(discount,adjustment), settings.currency)}</p>
                                                            </div>
                                                            <div className="col-1">
                                                                <BsThreeDots />
                                                            </div>
                                                        </div>                                                


                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 p-4">
                                            <h4 className={"mb-4 "}>Payment</h4>
                                            <p className={styles.p__rVieInfo}>Your event(s) will be booked using the selected pass(es). No further payment is required.</p>

                                            <div className="container-fluid no-gutters">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h5>Confirm and complete booking</h5>
                                                        <p className={styles.p__rVieInfo}>To proceed with the booking, please read carefully the information below and then click the book now.</p>
                                                        <PassPaymentTypeForm  onComplete={onPaymentComplete} onIdle={onIdle} onBusy={onBusy} disable={isErrorState()} cart={cart} terms={settings.terms} customer_terms={terms} />
                                                    </div>
                                                </div>
            

                                            </div>
                                        </div>                                
                                    </div>
                                </Layout>
                                
                            )

                        }
                        

                        case CHECKOUT_STAGE_CART_EMPTY:
                        {
                            return (
                                <Layout title="checkout" description={"checkout manager"}  layout={'checkout'} >
                                    <div className="row no-gutters">
                                        <div className={"col-12 col-md-6 p-4 " + styles.p__border}>
                                            <h4 className={"mb-4 "}>Cart</h4>
                                            <div className="container-fluid">
                                            {
                                                        cart.card.map( transaction => (
                                                            transaction.objects.map( product => ( 
                                                                <div className="row mb-3 mr-md-2" key={'card-'+product.id}>
                                                                    <div className="col-8">
                                                                        <h5 className={"mb-1 " + styles.p__cartItemName}>{product.name}</h5>
                                                                        <p className="mb-1">Date and time: {<Moment format="LLL">{product.date + "T" + product.time}</Moment>} </p>
                                                                        <p className="mb-1">Quantity: {product.qty }</p>

                                                                    </div>
                                                                    <div className="col-3">
                                                                        <p className="mb-1">{formatNumber(product.key_3.price, settings.currency)}</p>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <BsFillUnlockFill />
                                                                    </div>
                                                                </div>
                                                            ))))
                                                        } 

                                                        {
                                                            cart.pass.filter(item => ((item.transactions.length > 0 && item.mode === 'debit') || item.mode === 'credit')).map( product => (
                                                                <React.Fragment>
                                                                    <div className="row mb-3  mr-md-2">

                                                                        <div className="col-8">
                                                                            <h5 className={"mb-1 " + styles.p__cartItemName}>{product.name}</h5>
                                                                            <PassExpires product={product} styles={styles} />                                                                    

                                                                            
                                                                        </div>
                                                                        <div className="col-3">
                                                                            {
                                                                                product.mode === 'debit' && <div className={styles.p__payInfo}>Already purchased<div><small>(no additional charge)</small></div></div>

                                                                            }
                                                                            {
                                                                                product.mode === 'credit' &&   <p className="mb-1">{formatNumber(product.price, settings.currency)}</p>

                                                                            }
                                                                        </div>
                                                                        <div className="col-1">
                                                                            {
                                                                                product.mode === 'debit' && product.locked === OBJECT_LOCKED_STATE_UNLOCKED && <BsFillUnlockFill />
                                                                            }
                                                                            {
                                                                                product.mode === 'debit' && product.locked === OBJECT_LOCKED_STATE_LOCKED && <BsLockFill />
                                                                            }  
                                                                            {
                                                                                product.mode === 'debit' && product.locked === OBJECT_LOCKED_STATE_ERROR && 
                                                                                <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartPassDelete(product)}}/>                                                                           

                                                                            }                                                                              

                                                                            {
                                                                                product.mode === 'credit' && product.locked === OBJECT_LOCKED_STATE_UNLOCKED && <BsFillUnlockFill />
                                                                            }
                                                                            {
                                                                                product.mode === 'credit' && product.locked === OBJECT_LOCKED_STATE_LOCKED && <BsLockFill />
                                                                            } 
                                                                            {
                                                                                product.mode === 'credit' && product.locked === OBJECT_LOCKED_STATE_ERROR && 
                                                                                <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartPassDelete(product)}}/>                                                                           

                                                                            } 
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        product.transactions.map( t => (
                                                                            t.objects.map(( p ) => ( 
                                                                            <div className="row mb-2  mr-md-2 pl-2">
                                                                                <div className="col-5 ">
                                                                                    <h5 className={"mb-1 " + styles.p__cartItemName}>{p.name}</h5>
                                                                                    <p className="mb-1 ">Date and time: {<Moment format="LLL">{p.date + "T" + p.time}</Moment>} </p>
                                                                                    <p className="mb-1 ">Quantity: {p.qty }</p>

                                                                                </div>
                                                                                <div className="col-3">
                                                                                    <p className="mb-1">{p.key_3.credits} credit(s)</p>
                                                                                </div>
                                                                                <div className="col-3">
                                                                                    {formatNumber(0, settings.currency)}
                                                                                </div>                                                                            
                                                                                <div className="col-1">
                                                                                       {
                                                                                            p.locked === OBJECT_LOCKED_STATE_UNLOCKED &&
                                                                                            <BsFillUnlockFill  data-for="toolTipUnlocked"/>

                                                                                        }
                                                                                        {
                                                                                            p.locked === OBJECT_LOCKED_STATE_LOCKED &&
                                                                                            <BsLockFill  data-for="toolTipLocked" />                                                                           
                                                                                        }
                                                                                        {
                                                                                            p.locked === OBJECT_LOCKED_STATE_ERROR &&
                                                                                            <BsFillTrashFill  data-for="toolTipError" className={styles.p__iconDanger} onClick={() => {onCartItemDelete(p)}}/>                                                                           
                                                                                        }                                                                                
                                                                                </div>
                                                                            </div>
                                                                        ))))
                                                                    }
                                                                </React.Fragment>

                                                            ) )
                                                        }
                                                            

                                                        <div className="row mb-3  mr-md-2 pt-4 pb-4 border-top border-bottom">

                                                            <div className="col-8">
                                                                <h5 className={"mb-1 " + styles.p__cartItemName}>Total to pay now</h5>

                                                            </div>
                                                            <div className="col-3">
                                                                <p className={"mb-1 " + styles.p__rVieBold}>{formatNumber(SubTotal(discount,adjustment), settings.currency)}</p>
                                                            </div>
                                                            <div className="col-1">
                                                                <BsThreeDots />
                                                            </div>
                                                        </div>                                                


                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 p-4">
                                            <h4 className={"mb-4 "}>Cart empty</h4>
                                            <p className={styles.p__rVieInfo}>We are unable to proceed with this booking - your cart is empty. Please return to the schedule, passes or subscriptions and select an appropriate option.</p>
                                        </div>                                
                                    </div>
                                </Layout>
                                
                            )

                        }




                        case CHECKOUT_STAGE_PURCHASE_COMPLETE:
                        {
                            return ( 
                                <div>
                                    <Layout title="checkout" description={"checkout manager"}  layout={'checkout'} >
                                        <div className="row mb-4 p-3">
                                            <div className={"col-12 p-4 " + styles.p__border}>
                                                <h4 className={"mb-4 "}>Thank you</h4>
                                                <p className={"mb-4 "}>The checkout process is now complete. If you have purchased multiple passes or booked multiple, you may receive multiple emails for each item. You can also check your account at anytime to find your purchases.</p> 
                                                <p className={"mb-4 "}>To proceed, please select one of the options below: </p> 

                                                <div className="container-fluid no-gutters">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6 pt-4">
                                                            <button type="submit" onClick={onReturnToWebsite} className={"btn btn-md btn-outline-secondary btn-block "}>Return to website</button>
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-4">
                                                            <button type="submit"  onClick={onGotoAccount} className={"btn btn-md btn-primary btn-block"}>Go to my account</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>

                                    </Layout>                                
                                </div>       
                            )                               
                        }




                    default:
                    {
                        return ( 
                            <div></div>
                        )                        
                    }
                }

            }

            case 'login-proxy':
            {
     
                return ( 
                    <Layout title="checkout" description={"checkout manager"} layout="inline" >
                        <LogInCombined onChildIdle={onIdle} onChildBusy={onBusy} onReady={onParentLoggedIn} onLogin={onParentLogin} onRefreshSize={onRefreshParentSize}/>                            
                    </Layout>
                )   
                
            }


            case 'waitlist-proxy':
            {
     
                return ( 
                    <Layout title="checkout" description={"checkout manager"} layout="inline" >
                        <WaitBlock onChildIdle={onIdle} onChildBusy={onBusy} onReady={onParentLoggedIn} onLogin={onParentLogin} onRefreshSize={onRefreshParentSize}/>                            
                    </Layout>
                )   
                
            }

   
            default:
            {
                return ( 
                    <div></div>
                )
            }
        
        }



}




export default CheckoutController;
